<template>
  <div id="app">
    <main-layout id="main-layout"/>
  </div>
</template>

<script>


import MainLayout from "@/view/Main-layout";

export default {
  components: {
    MainLayout
  }
}
</script>

<style>
#app {
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
}
</style>